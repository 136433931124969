import React from "react"

import { Section } from "./styled"
import Text from "../../components/shared/Text"

const ErrorSection = () => {
    return (
        <Section>
            <div className="container">
                <Text variant="h1">404 - Не найдено</Text>
            </div>
        </Section>
    )
}

export default ErrorSection
