import React from "react"

import SEO from "../components/shared/Seo"
import ErrorSection from "../sections/Error"
import Layout from "../components/shared/Layout"

const NotFoundPage = () => (
    <Layout>
        <SEO title="Ошибка - 404: не найдено" />
        <ErrorSection />
    </Layout>
)

export default NotFoundPage
